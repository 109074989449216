import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import Notiflix from "notiflix";
import PostApiCall from "../../API/POSTAPI";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import Sidebar from "../../components/SideBar/Sidebar";
import Header from "../../components/Header/Header";

import { CSVLink, CSVDownload } from "react-csv";
import "./ProcessList.css";

class ProcessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      UserData: [],
      Id: "",
      searchInput: "",
      FromDate: null,
      ToDate: null,
      OrderData: [],
      ProcessData: [],
      SearchField: null,
    };
  }

  componentDidMount() {
    Notiflix.Loading.init({
      svgColor: "#777f80",
    });

    PostApiCall.postRequest(
      {
        WhereClause: "",
        // RecordCount: '*'
      },
      "getProcess"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            ProcessData: obj.data,
          });
        }
      })
    );
  }

  onPost = () => {
    Notiflix.Loading.dots("Please wait...");

    PostApiCall.postRequest(
      {
        RecordCount: "*",
        WhereClause: "",
      },
      "GetCustomerList"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            ProcessData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  };

  seachBarHandler = (e) => {

    this.setState({ ...this.state, searchInput: e.target.value });
  };

  render() {
    console.log(this.state.searchInput);

    // const data = {
    //   columns: [
    //     {
    //       label: "S.No.",
    //       field: "serial",
    //       sort: "enabled",
    //     },
    //     {
    //       label: "Process name.",
    //       field: "ProcessName",
    //       sort: "enabled",
    //     },
    //     {
    //       label: "ScriptPath",
    //       field: "script",
    //       sort: "enabled",
    //     },
    //     {
    //       label: "LastRun",
    //       field: "lastrun",
    //       sort: "enabled",
    //     },
    //     {
    //       label: "Execute",
    //       field: "action",
    //       sort: "enabled",
    //     },
    //   ],

    //   rows: this.state.ProcessData.filter((data) => {
    //     if (this.state.searchInput == "") {
    //       return data;
    //     }
    //     if (
    //       this.state.searchInput !== "" &&
    //       (data.fld_pname
    //         .toLowerCase()
    //         .includes(this.state.searchInput.toLowerCase()))
    //     ) {
    //       return data;
    //     }
    //   }).map((data, i) => {
    //     return {
    //       serial: i + 1,
    //       ProcessName: data.fld_pname,
    //       script: data.fld_pscript_path,
    //       lastrun: data.fld_last_run,
    //       action: (
    //         <span className="">
    //           <i
    //             class="bi bi-plus-square text-primary ms-3"
    //             onClick={() => {
    //               window.location.href = `/executeprocess/${data.fld_process_Id}`;
    //             }}
    //           ></i>
    //         </span>
    //       ),
    //     };
    //   }),
    // };

    return (
      <div class="container-fluid d-flex m-0 p-0 "
        style={{ backgroundColor: "#F8F8F8", transition: ".8s all" }}
      >
        <div style={{ backgroundColor: "white" }}>
          <Sidebar />
        </div>
        <div class="flex-fill">
          <Header />
          <div className="row">
            <div className="col-md-12">
              <ul class="breadcrumb">
                <li>
                  <a href="/dashboard">Global Trendz Server</a>
                </li>
                <li>Process</li>
              </ul>
            </div>
          </div>

          <div
            class="container-fluid py-3 rounded-3"
            style={{ backgroundColor: "#F8F8F8" }}
          >
            <div class="row mt-4 mx-1">
              <div
                class="row m-0 py-2 justify-content-between"
                style={{ backgroundColor: "#2952A1" }}
              >
                <h4 class="text-white col-md-2">Process List</h4>
              </div>

              <div class="input-group my-3 ">
                <div class="input-group-prepend ">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="bi bi-search"></i>
                  </span>
                </div>

                <input
                  type="text"
                  class="form-control"
                  placeholder="Type the search phrase you wish to search within the grid"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => this.seachBarHandler(e)}
                  value={this.state.searchInput}
                />
              </div>

              {/* <div class="row">
                {this.state.ProcessData.length !== 0 ? (
                  <MDBDataTable
                    responsive
                    hover
                    striped
                    bordered
                    data={data}
                    entriesOptions={[10, 25, 50, 100]}
                  />
                ) : (
                  <p>No Data Found</p>
                )}
              </div> */}


              <div className="row m-0 align-items-stretch">
                {this.state.ProcessData.filter((data) => {
                  if (this.state.searchInput == "") {
                    return data;
                  }
                  if (
                    this.state.searchInput !== "" &&
                    (data.fld_pname
                      .toLowerCase()
                      .includes(this.state.searchInput.toLowerCase()))
                  ) {
                    return data;
                  }
                }).map(data => {
                  return (
                    <div className="col-xl-3 col-lg-4 col-md-6 ">
                      <div className="m-2 p-2 border rounded-3">
                        <h6>ProcessName : {data.fld_pname}</h6>
                        <p style={{ wordBreak: "break-all" }} >script : {data.fld_pscript_path}</p>
                        <p>lastrun : {data.fld_last_run}</p>
                        <div className="row m-0 justify-content-end">
                          <a href={`/executeprocess/${data.fld_process_Id}`} className="col-auto btn btn-primary" >Execute</a>
                        </div>

                      </div>

                    </div>
                  )
                })}

              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProcessList;
