
import React, { Component } from 'react'
import { MDBDataTable } from "mdbreact";
import PostApiCall from "../../API/POSTAPI";
import Notiflix from 'notiflix';

class ProcessTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CustomerData: [],
        };

    }

    componentDidMount() {


        Notiflix.Loading.init({
            svgColor: "#0762a3",
        });
        Notiflix.Loading.dots("Please wait...");


        PostApiCall.postRequest(
            {
                WhereClause: ''

            },
            "getProcess"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {

                if (results.status == 200 || results.status == 201) {
                    //    console.log(obj.data);
                    this.setState({
                        CustomerData: obj.data,
                    });

                    Notiflix.Loading.remove();

                }
            }))
    }

    render() {
        const data = {
            columns: [

                {
                    label: 'Enq No.',
                    field: 'ProcessNo',
                    sort: 'disabled'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'disabled'
                },
                {
                    label: 'Mobile',
                    field: 'mobile',
                    sort: 'disabled'
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'disabled'
                },

                {
                    label: 'Country',
                    field: 'country',
                    sort: 'disabled'
                },


            ],
            rows: this.state.CustomerData.map((data, i) => {
                console.log(data)
                return {

                    ProcessNo: (data.FLD_ENQ_NO),
                    name: (data.fld_full_name),
                    mobile: (data.FLD_MOBILE_NUMBER),
                    email: (data.FLD_EMAIL_ID),
                    country: data.FLD_COUNTRY,
                }
            })
        }
        return (
            <MDBDataTable
                responsive
                hover
                striped
                bordered
                data={data}
                paging={false}
                className='table_height scr-bar'

            />

        )
    }
}

export default ProcessTable
