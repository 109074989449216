
import React from "react";


import Notiflix from "notiflix";
import PostApiCall from "../../API/POSTAPI";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/SideBar/Sidebar";




class ExecuteProcess extends React.Component {

    constructor(props) {
        super(props)
        const { id } = props.match.params;

        this.state = {
            ProcessId: id,

            Query: "",
            Output: "",
            Path: "",
            ProcessName: "",
            Pm2List: [],
            Pm2Stop: "",
            Pm2Start: "",
            Pm2Restart: "",
            JIRATicket: ""
        }
    }



    componentDidMount() {

        Notiflix.Loading.init({
            svgColor: "#0762a3",
        });
        Notiflix.Loading.dots("Please wait...");
        this.setState({
            Pm2List: this.state.Pm2List
        })


        PostApiCall.postRequest(
            { whereClause: ` where fld_process_Id = ${this.state.ProcessId}` }, "getProcess")
            .then(results => results.json().
                then(obj => {
                    if (results.status == 200 || results.status == 201) {
                        //  this.props.setstate(obj.data[0].label)
                        console.log(obj);
                        this.setState({
                            Path: obj.data[0].fld_pscript_path,
                            ProcessName: obj.data[0].fld_pname,
                            Pm2Restart: obj.data[0].fld_pm2_restart,
                        })

                        Notiflix.Loading.remove();
                    }
                })
            )
    }




    onComplete = (obj) => {
        console.log("hi");
        this.setState({
            Output: obj.result,
            Pm2Stop: obj.pm2stop,
            Pm2Start: obj.pm2start,
        });
    }

    JsonDataDisplay = (obj) => {
        console.log("obj " + obj)

        Object.keys(obj).map(
            (data, i) => {
                return (
                    <tr>
                        <td>{data.pid}</td>
                        <td>{data.name}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                )
            }
        )
    }




    render() {

        return (
            <div class="container-fluid d-flex m-0 p-0 " style={{ backgroundColor: "#F8F8F8", transition: ".8s all" }}>
                <div style={{ backgroundColor: "white" }}>
                    <Sidebar />
                </div>
                <div class="flex-fill">

                    <Header />

                    <div class="p-3 m-3 bg-white rounded-3 border" >
                        <div class="d-flex justify-content-between align-items-center">
                            <h2>Execute Query</h2>
                        </div>

                        <form class="row justify-content-between">

                            <div class="form-group col-md-4 mb-3" >
                                <label for="shellcommand">Custom shell command</label>
                                <input id="shellcommandinput" type="text" class="form-control" value='' />
                            </div>

                            <div class="form-group col-md-4 mb-3" >
                                <label for="shellcommand">Process Name</label>
                                <input id="shellcommandinput" type="text" disabled class="form-control" value={this.state.ProcessName} />
                            </div>

                            <div class="form-group col-md-4 mb-3" >
                                <label for="shellcommand">Path</label>
                                <input id="shellcommandinput" type="text" disabled class="form-control" value={this.state.Path} />
                            </div>



                            <div class="form-group col-md-12 mb-3">
                                <label for="customcommand">Output </label>



                            </div>
                            <div class="form-group col-md-12 mb-3">
                                <label for="customcommand">{this.state.Output} </label>



                            </div>
                            <div class="form-group col-md-12 mb-3">
                                <label for="customcommand">Pm2 Stop </label>


                            </div>
                            <div class="form-group col-md-12 mb-3">
                                {this.state.Pm2Stop}




                            </div>
                            <div class="form-group col-md-12 mb-3">
                                <label for="customcommand">Pm2 Start </label>


                            </div>
                            <div class="form-group col-md-12 mb-3">
                                {this.state.Pm2Start}




                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="JIRALink">
                                        JIRA Issue Link
                                        <span className="mandatory">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="CategoryName"
                                        className="form-control my-1 mr-sm-2"
                                        value={this.state.JIRATicket}
                                        onChange={(text) => {
                                            this.setState({
                                                JIRATicket: text.target.value,
                                            });
                                        }} />
                                </div>
                            </div>


                            <div class="col-xxl-2 col-lg-3 col-md-4 ms-auto ">
                                <button type="button" class="btn btn-primary rounded btn-lg btn-block my-4 ms-3 "
                                    onClick={async () => {
                                        Notiflix.Loading.dots("Please wait...");
                                        if (this.state.JIRATicket === "" || this.state.JIRATicket === null) {
                                            Notiflix.Notify.failure("Please Enter JIRA issue link")
                                        } else {
                                            const response = await fetch(PostApiCall.postRequest(
                                                {

                                                    path: this.state.Path,
                                                    processname: this.state.ProcessName,
                                                    Pm2Restart: this.state.Pm2Restart,
                                                    JIRATicket: this.state.JIRATicket
                                                },
                                                "executeshell"
                                            ).then((results) =>
                                                results.json().then((obj) => {

                                                    if (results.status == 200 || results.status == 201) {
                                                        this.onComplete(obj);

                                                        Notiflix.Loading.remove();
                                                    } else {
                                                        Notiflix.Loading.remove();
                                                    }
                                                })))
                                            console.log("response ");
                                            const json = await response.json();

                                        }
                                        Notiflix.Loading.remove();
                                    }}> Execute</button>
                            </div>

                        </form>

                    </div>
                </div>

            </div>

        )
    }
}

export default ExecuteProcess;