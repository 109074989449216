import React, { useState, useContext } from "react";
import "./Header.css";
import ProfileImg from "../../assets/images/avatar-13.png";
import Logout from "../../assets/images/logout.png";
import { store } from "../../store/Store";
import LogoImg from "../../assets/images/sLogo.png";

const HeaderMobile = () => {
  const { toggle, settoggle } = useContext(store);
  const [fullscrn, setfullscrn] = useState(false);

  const fullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  var login = localStorage.getItem("LoginData");
  var details = JSON.parse(login);

  return (
    <div class="row m-0 py-3 pe-4 bg-white">
      <div class=" col-md-2 ps-4 pt-1 ">
        <img
          src={LogoImg}
          style={{
            width: "50px",
            marginLeft: "25%",
            objectFit: "contain",
          }}
        />
      </div>

      <div
        class=" col-md-4 d-flex justify-content-between align-items-center ms-auto"
        style={{ width: "340px" }}
      >
        <div class="dropdown">
          <i
            class="bi bi-bell header_icon pulse-wave dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          ></i>

          <ul class=" animated bounceIn dropdown-menu">
            <li class="header">
              <div style={{ padding: "10px" }}>
                <div class="d-flex justify-content-between align-items-center">
                  <h4 class="mb-0 mt-0">Notifications</h4>
                  <a href="#" class="text-danger">
                    Clear All
                  </a>
                </div>
              </div>
            </li>

            <li>
              <div class="overflow-auto py-2" style={{ height: "250px" }}>
                <ul style={{ overflow: "hidden", width: "auto" }}>
                  <li>
                    <a href="#">
                      <i class="fa fa-users text-info"></i> Curabitur id eros
                      quis nunc suscipit blandit.
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <i class="fa fa-warning text-warning"></i> Duis malesuada
                      justo eu sapien elementum, in semper diam posuere.
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <i class="fa fa-users text-danger"></i> Donec at nisi sit
                      amet tortor commodo porttitor pretium a erat.
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <i class="fa fa-shopping-cart text-success"></i> In
                      gravida mauris et nisi
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <i class="fa fa-user text-danger"></i> Praesent eu lacus
                      in libero dictum fermentum.
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <i class="fa fa-user text-primary"></i> Nunc fringilla
                      lorem
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <i class="fa fa-user text-success"></i> Nullam euismod
                      dolor ut quam interdum, at scelerisque ipsum imperdiet.
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="text-center">
              <a href="#" style={{ color: "gray" }}>
                {" "}
                View all
              </a>
            </li>
          </ul>
        </div>

        <i class="bi bi-chat-left header_icon"></i>
        <i class="bi bi-fullscreen header_icon" onClick={fullScreen}></i>

        <span>
          <span style={{ fontSize: "14px" }}>{details[0].fld_name}</span>
          <img
            src={ProfileImg}
            style={{
              backgroundColor: "#d7ecfc",
              marginLeft: "5px",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
          />
        </span>

        <img
          src={Logout}
          width="40px"
          height="40px"
          onClick={() => {
            localStorage.clear();
            window.location.href = "/";
          }}
          style={{
            marginRight: "10px",
            backgroundColor: "#d7ecfc",
            borderRadius: "10px",
          }}
        />
      </div>
    </div>
  );
};

export default HeaderMobile;
