import { createContext,useState } from "react";

export const store = createContext()


const Provider = ({children}) => {
    const [toggle, settoggle] = useState(false)
    return(
        <store.Provider value={{toggle,settoggle}}>
        {children}
        </store.Provider>
    )
}

export default Provider