

import Notiflix from 'notiflix';
import React, { useState } from 'react'
import QrReader from 'react-qr-reader';

const QrScan = () => {
    const [Data, setData] = useState(null)
    const [cameraShow, setcameraShow] = useState(false)


    const handleScanFile = async (result) => {

        if (result) {

            let apiCall = await fetch("http://admin.hideindia.com/hide-api/updatecheckinstatus", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ hideid: result }),
            })

            const apiresult = await apiCall.json()


            if (apiresult.data[0].message == "Check in status updated") {
                Notiflix.Loading.remove()
                Notiflix.Notify.success("Visitor Checked In Successfully")
                window.location.reload()

            } else {
                Notiflix.Loading.remove()
                Notiflix.Notify.failure("Something wrong. Please Scan Again")
                window.location.reload()

            }
        }
    }


    return (

        <QrReader
            delay={300}
            style={{ width: "100%", height: "100vh" }}
            onError={(e) => console.log(e)}
            onScan={handleScanFile}
        />

    )
}

export default QrScan