import React, { Component } from "react";
import PostApiCall from "../API/POSTAPI";
import moment from "moment";
import Notiflix from "notiflix";
import Logo from "../assets/images/sLogo.png";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import LoginIcon from "../assets/images/server2.png";

var otp = Math.floor(100000 + Math.random() * 900000);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NumRegex: /^[0-9]*$/,
      isPasswordVisible: false,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^[a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-z0-9]@[a-z0-9][-\.]{0,1}([a-z][-\.]{0,1})*[a-z0-9]\.[a-z0-9]{1,}([\.\-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/,
      UrlRegex:
        /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      open: false,
      EnteredOtp: "",
      MailerOtp: "",
      Email: "",

      Password: "",

      Salt: [],
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      Name: "",
      MobileNo: "",
      EmailAddress: "",
      RegisterPassword: "",
      ConfirmPassword: "",

      EmailId: "",
      showemailModal: false,
      showotpModal: false,
      NewPassword: "",
    };
  }
  componentDidMount() {}

  saveLogin() {
    if (this.state.Email != "") {
      if (
        this.state.EmailRegex.test(this.state.Email) ||
        this.state.NumRegex.test(this.state.Email)
      ) {
        if (this.state.Password != "") {
          this.onLogin();
        } else {
          Notiflix.Notify.failure("Please enter your password.");
        }
      } else {
        Notiflix.Notify.failure("Please enter valid email or mobile number.");
      }
    } else {
      Notiflix.Notify.failure("Please enter your email or mobile number.");
    }
  }

  onLogin = () => {
    PostApiCall.postRequest(
      {
        email: this.state.Email,
        password: this.state.Password,
        action: "Login",
        actiondate: moment().format("lll"),
      },
      "addwebistelogin"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          (results.status == 200 || results.status == 201) &&
          localStorage.getItem("LoginData")
        ) {
          console.log(obj.data);
          localStorage.setItem("LoginData", JSON.stringify(obj.data));
          this.props.history.push("/dashboard");
        } else if (results.status == 200 || results.status == 201) {
          localStorage.setItem("LoginData", JSON.stringify(obj.data));
          this.props.history.goBack();
        } else {
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  // ====Forget Password=========

  onPostSalt() {
    PostApiCall.postRequest(
      {
        email: this.state.EmailId,
      },
      "getsalt_byemailid"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            Salt: obj.data,
          });
        } else {
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  SaveEmail() {
    if (this.state.EmailId != "") {
      if (this.state.EmailRegex.test(this.state.EmailId)) {
        this.onEmailPost();
      } else {
        Notiflix.Notify.failure("Please enter valid email address.");
      }
    } else {
      Notiflix.Notify.failure("Please enter your email address.");
    }
  }

  onEmailPost = () => {
    Notiflix.Loading.dots("Please wait...");
    PostApiCall.postRequest(
      {
        OTP: otp,
        email: this.state.EmailId,
      },
      "Mailer_ForgotPassword"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success(
            "Your verification OTP is successfully sent to your email address.."
          );
          this.onPostSalt();
          this.setState({
            showotpModal: true,
            showemailModal: false,
          });
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  // =======Verify OTP========

  VerifyOtp() {
    if (this.state.EnteredOtp != "") {
      if (this.state.EnteredOtp == otp) {
        this.setState({
          openPassword: true,
          showotpModal: false,
        });
      } else {
        Notiflix.Notify.failure("Incorrect OTP.");
      }
    } else {
      Notiflix.Notify.failure("Please enter OTP.");
    }
  }

  // =======For Password Save=========
  SavePassword() {
    if (this.state.NewPassword != "") {
      if (this.state.ConfirmPassword != "") {
        if (this.state.ConfirmPassword == this.state.NewPassword) {
          this.onPostPassword();
        } else {
          Notiflix.Notify.failure(
            "New Password and Confirm Password do not match "
          );
        }
      } else {
        Notiflix.Notify.failure("Please enter confirm password.");
      }
    } else {
      Notiflix.Notify.failure("Please enter new password.");
    }
  }

  onPostPassword = () => {
    console.log(this.state.Salt[0].fld_salt);
    Notiflix.Loading.dots("Please wait...");

    PostApiCall.postRequest(
      {
        email: this.state.EmailId,
        password: this.state.ConfirmPassword,
        salt: this.state.Salt[0].fld_salt,
        updatedon: moment().format("lll"),
        updatedby: 0,
      },
      "changepassword"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Notify.success("Password Successfully changed");
          window.location.reload();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  render() {
    return (
      <div
        class="container-fluid"
        style={{
          backgroundColor: "#F8F8F8",
          width: "100%",
          height: "100vh",
          paddingTop: "150px",
        }}
      >
        <div class="row align-items-center justify-content-md-center">
          <div class="col-12">
            <div class="row justify-content-center g-0 ">
              <div class="col-lg-6 bg-white rounded-3 shadow-lg row m-0 p-0  ">
                <div class="col-md-5 loginimg">
                  <img
                    src={LoginIcon}
                    width="105%"
                    height="400"
                    alt="sLogo.png"
                  />
                </div>
                <div class=" col-md-7  p-3 ">
                  <div class="content-top-agile p-20 pb-0 text-center">
                    <img
                      src={Logo}
                      style={{ width: "80px", objectFit: "contain" }}
                      alt=""
                    />
                    <h5 class="mb-5" style={{ color: "#f56816" }}>
                      GT Server Management System
                    </h5>
                  </div>
                  <div class="p-40">
                    <div class="form-group">
                      <div class="input-group mb-3">
                        <span class="input-group-text bg-transparent border border-light">
                          <i
                            class="bi bi-person-fill"
                            style={{ color: "#0762a3" }}
                          ></i>
                        </span>
                        <input
                          type="text"
                          class="form-control ps-15 bg-transparent"
                          placeholder="Enter your Email Id "
                          value={this.state.Email}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              Email: e.target.value,
                            })
                          }
                        ></input>
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="input-group mb-3">
                        <span class="input-group-text bg-transparent border border-light">
                          <i
                            class="bi bi-lock-fill"
                            style={{ color: "#0762a3" }}
                          ></i>
                        </span>
                        <input
                          type={
                            this.state.isPasswordVisible ? "text" : "password"
                          }
                          placeholder="Enter your Password"
                          class="form-control ps-15 bg-transparent"
                          value={this.state.Password}
                          onChange={(e) =>
                            this.setState({
                              ...this.state,
                              Password: e.target.value,
                            })
                          }
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              this.saveLogin();
                            }
                          }}
                        ></input>
                        <span class="login-icon-change-pass">
                          <i
                            style={{
                              color: this.state.isPasswordVisible
                                ? "#0762a3"
                                : "#629df0",
                            }}
                            className="bi bi-eye-fill"
                            onClick={() => {
                              this.setState({
                                isPasswordVisible:
                                  !this.state.isPasswordVisible,
                              });
                            }}
                          />
                        </span>
                      </div>
                    </div>

                    {/* <div class="password-icon" onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })} >
                                            {this.state.isPasswordVisible ? <i class="bi bi-eye-fill"></i> : <i class="bi bi-eye-slash-fill"></i>}
                                        </div> */}

                    <div class="row mt-4 mb-5 justify-content-between align-items-center ">
                      <div className="col-md-12">
                        <a
                          className="forgotpswrdtext"
                          onClick={() => {
                            this.setState({
                              showemailModal: true,
                            });
                          }}
                        >
                          Forgot password ?
                        </a>
                      </div>
                      <div className="col-md-12">
                        <button
                          class="btn btn-primary loginbtn"
                          onClick={this.saveLogin.bind(this)}
                        >
                          Login
                        </button>
                      </div>

                      <Modal
                        class="modal-content"
                        open={this.state.showemailModal}
                        onClose={() => {
                          this.setState({ showemailModal: false });
                        }}
                        center
                      >
                        <div class="modal-content modelcontent2">
                          <div class="modal-header">
                            <h4 class="modal-title">Recover Password</h4>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group mb-3">
                                  <label for="validationCustom01">
                                    Email Address{" "}
                                    <span class="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={this.state.EmailId}
                                    onChange={(text) => {
                                      this.setState({
                                        EmailId: text.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              class="btn btn-primary"
                              type="submit"
                              style={{ float: "right" }}
                              onClick={() => {
                                this.setState({
                                  showemailModal: false,
                                });
                              }}
                            >
                              Close
                            </button>

                            <button
                              class="btn btn-primary"
                              type="submit"
                              style={{ float: "right" }}
                              onClick={this.SaveEmail.bind(this)}
                              data-toggle="modal"
                            >
                              Next
                            </button>
                            <span></span>
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        class="modal-content"
                        open={this.state.showotpModal}
                        center
                      >
                        <div class="modal-content modelcontent2">
                          <div class="modal-header">
                            <h4 class="modal-title">Enter Verification Code</h4>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group mb-3">
                                  <input
                                    type="text"
                                    class="form-control  mb-3"
                                    id="formGroupExampleInput"
                                    placeholder="Enter verification code"
                                    value={this.state.EnteredOtp}
                                    onChange={(otp) => {
                                      if (
                                        this.state.NumRegex.test(
                                          otp.target.value
                                        ) &&
                                        otp.target.value.length <= 10
                                      ) {
                                        this.setState({
                                          EnteredOtp: otp.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              class="btn btn-primary"
                              type="submit"
                              style={{ float: "right" }}
                              onClick={() => {
                                this.setState({
                                  showotpModal: false,
                                });
                              }}
                            >
                              Close
                            </button>

                            <button
                              class="btn btn-primary"
                              type="submit"
                              style={{ float: "right" }}
                              onClick={this.VerifyOtp.bind(this)}
                              data-toggle="modal"
                            >
                              Next
                            </button>
                            <span></span>
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        class="modal-content"
                        open={this.state.openPassword}
                        onClose={() => {
                          this.setState({ openPassword: false });
                        }}
                        center
                      >
                        <div class="modal-content ">
                          <div class="modal-header">
                            <h4 class="modal-title">Change Password</h4>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group mb-3">
                                  <label for="validationCustom01">
                                    New Password{" "}
                                    <span class="mandatory">*</span>
                                  </label>
                                  <input
                                    type={
                                      this.state.isNewPasswordVisible
                                        ? "text"
                                        : "password"
                                    }
                                    class="form-control"
                                    value={this.state.NewPassword}
                                    onChange={(text) => {
                                      this.setState({
                                        NewPassword: text.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group mb-3">
                                  <label for="validationCustom01">
                                    Confirm Password{" "}
                                    <span class="mandatory">*</span>
                                  </label>
                                  <input
                                    type={
                                      this.state.isConPasswordVisible
                                        ? "text"
                                        : "password"
                                    }
                                    class="form-control"
                                    value={this.state.ConfirmPsssword}
                                    onChange={(text) => {
                                      this.setState({
                                        ConfirmPassword: text.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              class="btn btn-primary"
                              type="submit"
                              style={{ float: "right" }}
                              onClick={() => {
                                this.setState({
                                  openPassword: false,
                                });
                              }}
                            >
                              Close
                            </button>

                            <button
                              class="btn btn-primary"
                              type="submit"
                              style={{ float: "right" }}
                              onClick={this.SavePassword.bind(this)}
                            >
                              Save
                            </button>
                            <span></span>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-12 copyrighttext">
                <p style={{ textAlign: "center" }}>
                  {" "}
                  Copyright &copy; {moment().format("YYYY")}{" "}
                  <a
                    href="https://www.globaltrendz.com/"
                    target="_blank"
                    className="copyrightlink"
                  >
                    <b>Global Trendz</b>
                  </a>
                  . All rights reserved. <br /> Powered by{" "}
                  <a
                    href="globaltrendz.com"
                    target="_blank"
                    className="copyrightlink"
                  >
                    Global Trendz
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
