
import React, { Component } from 'react'
import { MDBDataTable } from "mdbreact";
import PostApiCall from "../../API/POSTAPI";
import Notiflix from 'notiflix';

class RegistrationTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CustomerData: [],
        };

    }

    componentDidMount() {

        Notiflix.Loading.init({
            svgColor: "#0762a3",
        });
        Notiflix.Loading.dots("Please wait...");

        PostApiCall.postRequest(
            {
                WhereClause: ''

            },
            "getstudentdtlinfo"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {

                if (results.status == 200 || results.status == 201) {
                  console.log(obj.data)
                    this.setState({
                        CustomerData: obj.data,
                    });
                    console.log(this.state.CustomerData)

                    Notiflix.Loading.remove();

                }
            }))
    }

    render() {
        //this.state.CustomerData)
        const data = {
            columns: [
                {
                    label: 'Student No.',
                    field: 'REG_NUMBER',
                    sort: 'disabled'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'disabled'
                },
                {
                    label: 'Mobile',
                    field: 'mobile',
                    sort: 'disabled'
                },
                {
                    label: 'DOB',
                    field: 'dob',
                    sort: 'disabled'
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'disabled'
                },




            ],
            rows: this.state.CustomerData.map((data, i) => {
                console.log(data)
                return {
                  
                    REG_NUMBER: (data.FLD_REG_NUMBER),
                    name: (data.fld_full_name),
                    mobile: (data.FLD_STUDENT_PHONE),
                    email: (data.FLD_STUDENT_EMAIL),
                    dob: data.FLD_DOB,
                }
            })
        }
        return (
            <MDBDataTable
                responsive
                hover
                striped
                bordered
                data={data}
                paging={false}


            />

        )
    }
}

export default RegistrationTable
