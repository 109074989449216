import React from "react";
import "./App.css";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import ProcessList from "./pages/ProcessList/ProcessList";
import ExecuteProcess from "./pages/ProcessList/ExecuteProcess";
import QRScan from "./pages/ProcessList/QRScan"

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/deploy" component={ProcessList} />
          <Route exact path="/dashboard" component={HomePage} />
          <Route exact path="/QRScan" component={QRScan} />
          <Route exact path="/executeprocess/:id" component={ExecuteProcess} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
