import React, { useContext, useState, useEffect } from "react";
import "./SideBar.css";
import { Link } from "react-router-dom";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import { store } from "../../store/Store";

import HamBurgur from "../../assets/images/menu icon/Hamburgur.png";
import PostApiCall from "../../API/POSTAPI";
import Notiflix from "notiflix";
import LogoImg from "../../assets/images/sLogo.png";

import { isMobile } from "react-device-detect";

const Sidebar = () => {
  const { toggle, settoggle } = useContext(store);

  const [menuData, setmenuData] = useState([]);

  useEffect(() => {
    var login = localStorage.getItem("LoginData");
    var details = JSON.parse(login);

    Notiflix.Loading.init({
      svgColor: "#0762a3",
    });

    Notiflix.Loading.dots("Please wait...");

    PostApiCall.postRequest(
      {
        designation: details[0].fld_designation,
      },
      "GetUserMenu"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setmenuData(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  return (
    <div
      // className="sidebar_parent"
      style={{
        width: toggle ? "280px" : "70px",
        padding: "0px 10px 10px 10px",
      }}
    > 
      <div class="">
        <div
          onClick={() => settoggle(!toggle)}
          style={{
            opacity: ".7",
            fontSize: "17px",
            color: "inherit",
            transform: toggle ? "translateX(240px)" : "none",
            transition: "all 600ms ease",
          }}
        >
          {toggle ? (
            <BsArrowLeftSquareFill
              style={{
                marginRight: "10px",
                backgroundColor: "#d7ecfc",
              }}
            />
          ) : (
            <img
              src={HamBurgur}
              style={{
                marginRight: "10px",
                backgroundColor: "#d7ecfc",
                borderRadius: "10px",
              }}
              height="40px"
              width="40px"
              alt=""
            />
          )}
        </div>
      </div>


      {menuData.map((item) => {
        return (
          <div class="sidebar_link">
            <Link
              to={item.fld_pagename}
              style={{ opacity: ".7", fontSize: "17px", color: "inherit" }}
            >
              <img
                src={item.fld_iconurl}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title={item.fld_menuname}
                style={{
                  marginRight: "10px",
                  backgroundColor: "#d7ecfc",
                  borderRadius: "10px",
                }}
                height="40px"
                width="40px"
                alt=""
              />
              {toggle && <span>{item.fld_menuname}</span>}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
