import React, { useState, useEffect, useContext } from "react";
import Banner from "../components/Banner";
import Change_schedule from "../components/Change Schedule/Change_schedule";
import Header from "../components/Header/Header";
import NoticeBoard from "../components/NoticeBoard/NoticeBoard";
import Schedule_box from "../components/schedulebox/Schedule_box";
import Sidebar from "../components/SideBar/Sidebar";
import Student_details_card from "../components/studentdetailscard/Student_details_card";
import ProcessTable from "../components/table/ProcessTable";
import RegistrationTable from "../components/table/RegistrationTable";

import Footer from "../components/Footer";

import { store } from "../store/Store";
import { isMobile } from "react-device-detect";
import HeaderMobile from "../components/Header/HeaderMobile";

const HomePage = () => {
  const { toggle, settoggle } = useContext(store);

  const [role, setrole] = useState();

  useEffect(() => {
    var login = localStorage.getItem("LoginData");
    var details = JSON.parse(login);

    setrole(details[0].fld_designation);
  }, []);

  window.addEventListener("scroll", (event) => {
    let topbtn = document.getElementById("top_btn");

    if (window.scrollY > 300) {
      topbtn.style.display = "block";
    } else if (window.scrollY > 200) {
      topbtn.style.display = "none";
    }
  });

  return (
    <div
      class="container-fluid d-flex m-0 p-0 "
      style={{ backgroundColor: "#F8F8F8", transition: ".8s all" }}
    >
      {/* <div style={{ backgroundColor: "white" }}> */}
      <Sidebar />
      {/* </div> */}
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          id="top_btn"
          onClick={() => console.log("rahul")}
          style={{
            display: "none",
            position: "fixed",
            bottom: "50px",
            right: "50px",
            zIndex: "999",
          }}
        >
          <a
            class="bi bi-arrow-up-square-fill"
            style={{ fontSize: "50px", color: "blue" }}
            href="#top"
          ></a>
        </div>

        {isMobile ? <HeaderMobile /> : <Header />}
      </div>
    </div>
  );
};

export default HomePage;
